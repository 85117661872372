<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Trenger du hjelp?
        </h1>
        <div class="article-body clearfix mb-3">
          <p>
            Velkommen til supportsiden for IntraFish. Husk at du alltid kan kontakte oss direkte via <a href="mailto:abonnement@intrafish.no">e-post</a> eller <a href="/kontaktoss">telefon</a> om du ikke finner det du leter etter her.
          </p>
          <p>
            Har du spørsmål om abonnement? Sjekk vår <a href="https://www.intrafish.no/myifm/faq/">FAQ</a>
          </p>
          <p class="mt-5">
            <strong>Innlogging</strong><br>
            Hvis du har glemt din bruker-ID eller ditt passord, kan du kontakte oss på <a href="mailto:abonnement@intrafish.no">abonnement@intrafish.no</a> eller på telefon +47 55 21 33 00. Vennligst ha klart ditt abonnementsnummer hvis mulig.
          </p>
          <p>
            Dersom du ikke får logget inn, kan det hende du er innlogget på flere enheter. Sørg for at du logger ut av mobiltelefon, nettbrett og PC etter bruk.
          </p>
          <p>
            Kommer du fortsatt ikke inn, prøv å slette cookies/informasjonskapsler i nettleserloggen. Lukk nettleseren og prøv å logge inn på nytt.
          </p>
          <p>
            Dersom problemet vedvarer, vennligst ta kontakt.
          </p>
          <p class="mt-5">
            <strong>Informasjonskapsler (cookies)</strong><br>
            For å logge inn på IntraFish.no må din nettleser være satt til å akseptere informasjonskapsler (cookies).
          </p>
          <p class="mt-5">
            <strong>Abonnementsbetaling</strong><br>
            Alle nødvendige detaljer for ditt individuelle abonnement finner du på tilsendt faktura.
          </p>
          <p class="mt-5">
            <strong>Støtte</strong><br>
            Hvis du har problemer eller spørsmål, kontakt oss på <a href="mailto:abonnement@intrafish.no">abonnement@intrafish.no</a>.
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'help-page',
  extends: Page
};
</script>
